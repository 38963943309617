import hero_img from "../assets/images/sitelogo.png";

import cat_web_development from "../assets/images/shirt.jpg";

import cat_python from "../assets/images/cleancub.jpg";



const other_images = {hero_img};

const categories_images = [cat_web_development,cat_python ];

export {other_images, categories_images};