import React from 'react';
import styled from "styled-components";
import { other_images } from '../utils/images';
import  sitelogo from "../assets/images/sitelogo.png"

const Hero = () => {
  return (
<HeroWrapper>
      <div className="container">
        <div className="hero-content">
          <h1>Since 2015</h1>
          <p>Specializing in e-commerce, we work across multiple platforms, including Amazon, Walmart, and eBay, to ensure your products reach their full online potential.</p>
        </div>
        <div className="hero-image">
          <img src={sitelogo} alt="Site Logo" />
        </div>
      </div>
    </HeroWrapper>
  )
}
const HeroWrapper = styled.div`
  background-color: white;
  height: 300px;
  display: flex;
  align-items: center;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;

    .hero-content {
      background-color: var(--clr-white);
      max-width: 400px;
      width: 100%;
      padding: 20px;

      h1 {
        font-size: 32px;
        margin-bottom: 5px;
        white-space: nowrap;
      }

      p {
        font-size: 15px;
      }
    }

    .hero-image {
      img {
        max-width: 300px;
        height: auto;
      }
    }
  }

  /* Media query for mobile view */
  @media (max-width: 768px) {
    .container {
      flex-direction: column; /* Stack elements vertically */
      align-items: center;
      text-align: center;
    }

    .hero-content {
      order: 1; /* Text on top */
      margin-bottom: 20px; /* Space between text and image */
    }

    .hero-image {
      order: 2; /* Image at the bottom */
    }
  }
`;


export default Hero