import React from 'react';
import styled from 'styled-components';

const About = () => {
  return (
    <AboutWrapper>
      <AboutContainer>
        <h1>About Us</h1>
        <AboutParagraph>
        At VanDelay Online, we've been helping brands successfully bring their products to the digital marketplace since 2015.
         Specializing in e-commerce, we work across multiple platforms, including Amazon, Walmart, and eBay,
          to ensure your products reach their full online potential. Our expertise and commitment to our
           partners drive growth and deliver results in the ever-evolving world of online retail.
        </AboutParagraph>
        <ContactDetails>
          <DetailItem>
            <DetailTitle>Address:</DetailTitle>
            <DetailContent>
            17205 211th Ave NW,<br />
            Big Lake, MN 55309
            </DetailContent>
          </DetailItem>
          <DetailItem>
            <DetailTitle>Phone:</DetailTitle>
            <DetailContent>
              <a href="tel:+12183296086">+1 218 329 6086</a>
            </DetailContent>
          </DetailItem>
          <DetailItem>
            <DetailTitle>Email:</DetailTitle>
            <DetailContent>
              <a href="mailto:rick@vandelayonline.com">rick@vandelayonline.com</a>
            </DetailContent>
          </DetailItem>
          <DetailItem>
            {/* <DetailTitle>Follow Us:</DetailTitle>
            <SocialLinks>
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
            </SocialLinks> */}
          </DetailItem>
        </ContactDetails>
      </AboutContainer>
    </AboutWrapper>
  );
}

const AboutWrapper = styled.div`
  background-color: white; /* Light blue background */
  padding: 20px;
`;

const AboutContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

const AboutParagraph = styled.p`
  font-size: 18px;
  color: #333;
  line-height: 1.6;
  margin-top: 20px;
`;

const ContactDetails = styled.div`
  margin-top: 40px;
`;

const DetailItem = styled.div`
  margin-bottom: 20px;
`;

const DetailTitle = styled.h2`
  color: #1e90ff; /* DodgerBlue */
  margin-bottom: 10px;
`;

const DetailContent = styled.p`
  font-size: 16px;
  color: #333;
  a {
    color: #1e90ff;
    text-decoration: none;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  a {
    margin: 0 10px;
    color: #1e90ff;
    text-decoration: none;
  }
`;

export default About;
