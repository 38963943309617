import React from 'react';
import Hero from "../components/Hero";
import CoursesList from "../components/CourseList";
import CategoriesList from "../components/CategoriesList";

const HomePage = () => {
  return (
    <div className='holder' style={{ paddingTop: '80px' }}>
      <Hero />
      <CategoriesList />
      <CoursesList />
    </div>
  )
}

export default HomePage