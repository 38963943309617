import React from 'react';
import Hero from "../components/Hero";
import CoursesList from "../components/CourseList";
import CategoriesList from "../components/CategoriesList";

const CategoryPage = () => {
  return (
    <div className='holder'>
      <CategoriesList />
    </div>
  )
}

export default CategoryPage