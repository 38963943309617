import './App.css';
import {
  BrowserRouter, Routes, Route
} from 'react-router-dom';
import {Home, SingleCourse, Courses, About, Categories, Contact} from "./pages";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Sidebar />
      <Routes>
        <Route path = "/about" element = {<About />} />
        <Route path = "/contact" element = {<Contact />} />
        <Route path = "/categories" element = {<Categories />} />
        <Route path = "/" element = {<Home />} />
        <Route path = "/product/:id" element = {<SingleCourse />} />
        <Route path = "/category/:category" element = {<Courses />} />
        {/* <Route path = "/cart" element = {<Cart />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
