import { course_images } from "./images";

const courses = [
    {
        id: "B08F9N58VY",
        category: "Houndstooth Shirts",
        image: "https://m.media-amazon.com/images/I/51lZ757KriL.jpg",
        course_name: "Men’s Houndstooth Button Up Short Sleeve Lounge Shirt | Black & White Casual Camp Shirt (Small)",
        description: "<p><b>Perfect for Dates, Halloween parties, or just casual hanging out with the boys. This Houndstooth shirt will give your personality a perfect first impression. Great gift to someone you love</b></p>. <br> <p><b>The Short sleeve casual button down shirt is made of polyester. Soft and comfortable to wear. Good Permeability and moisture absorption ability. Machine washable, low iron if necessary. You can wear it in many outdoor and indoor occasions.</b></p> <br> <p> This shirt fits perfectly with incredibly good polyester fabric according to your fashion.Whether you live in a mansion or a trailer this shirt will fit right in. </p> <p><b>Don\u2019t miss the chance and buy it now for an amazing experience!</b></p>",
        discounted_price: 44.95,
        product_link: "https://www.amazon.com/dp/B01FC1Q80W/"
    },
    {
        id: "B078XKFQ51",
        category: "Houndstooth Shirts",
        image: "https://m.media-amazon.com/images/I/51lZ757KriL.jpg",
        course_name: "Men’s Houndstooth Button Up Short Sleeve Lounge Shirt | Black & White Casual Camp Shirt (Medium)",
        description: "<p><b>Perfect for Dates, Halloween parties, or just casual hanging out with the boys. This Houndstooth shirt will give your personality a perfect first impression. Great gift to someone you love</b></p>. <br> <p><b>The Short sleeve casual button down shirt is made of polyester. Soft and comfortable to wear. Good Permeability and moisture absorption ability. Machine washable, low iron if necessary. You can wear it in many outdoor and indoor occasions.</b></p> <br> <p> This shirt fits perfectly with incredibly good polyester fabric according to your fashion.Whether you live in a mansion or a trailer this shirt will fit right in. </p> <p><b>Don\u2019t miss the chance and buy it now for an amazing experience!</b></p>",
        discounted_price: 44.95,
        product_link: "https://www.amazon.com/dp/B01FC1Q80W/"
    },
    {
        id: "B01FC1Q80W",
        category: "Houndstooth Shirts",
        image: "https://m.media-amazon.com/images/I/51lZ757KriL.jpg",
        course_name: "Men\u2019s Houndstooth Button Up Short Sleeve Lounge Shirt | Black & White Casual Camp Shirt (Large)",
        description: "<p><b>Perfect for Dates, Halloween parties, or just casual hanging out with the boys. This Houndstooth shirt will give your personality a perfect first impression. Great gift to someone you love</b></p>. <br> <p><b>The Short sleeve casual button down shirt is made of polyester. Soft and comfortable to wear. Good Permeability and moisture absorption ability. Machine washable, low iron if necessary. You can wear it in many outdoor and indoor occasions.</b></p> <br> <p> This shirt fits perfectly with incredibly good polyester fabric according to your fashion.Whether you live in a mansion or a trailer this shirt will fit right in. </p> <p><b>Don\u2019t miss the chance and buy it now for an amazing experience!</b></p>",
        discounted_price: 44.95,
        product_link: "https://www.amazon.com/dp/B01FC1Q80W/"
    },
    {
        id: "B01FC2IBFG",
        category: "Houndstooth Shirts",
        image: "https://m.media-amazon.com/images/I/91scBlLxUhL.jpg",
        course_name: "Men\u2019s Houndstooth Button Up Short Sleeve Lounge Shirt | Black & White Casual Camp Shirt (XL)",
        description: "<p><b>Perfect for Dates, Halloween parties, or just casual hanging out with the boys. This Houndstooth shirt will give your personality a perfect first impression. Great gift to someone you love</b></p>. <br> <p><b>The Short sleeve casual button down shirt is made of polyester. Soft and comfortable to wear. Good Permeability and moisture absorption ability. Machine washable, low iron if necessary. You can wear it in many outdoor and indoor occasions.</b></p> <br> <p> This shirt fits perfectly with incredibly good polyester fabric according to your fashion.Whether you live in a mansion or a trailer this shirt will fit right in. </p> <p><b>Don\u2019t miss the chance and buy it now for an amazing experience!</b></p>",
        discounted_price: 45.0,
        product_link: "https://www.amazon.com/dp/B01FC2IBFG/"
    },
    {
        id: "8968",
        category: "Houndstooth Shirts",
        image: "https://m.media-amazon.com/images/I/91scBlLxUhL.jpg",
        course_name: "Men's Houndstooth Button Down Short Sleeve Shirt (2XL) Black & White",
        description: "* 97% Cotton, 3% Spandex, Lining: Polyester * Longtail * Spread Collar",
        discounted_price: 44.95,
        product_link: "https://www.amazon.com/dp/B01FC1Q80M/"
    },
    {
        id: "6627",
        category: "Houndstooth Shirts",
        image: "https://m.media-amazon.com/images/I/51lZ757KriL.jpg",
        course_name: "Men\u2019s Houndstooth Button Up Short Sleeve Lounge Shirt | Black & White Casual Camp Shirt (3XL)",
        description: "<p><b>Perfect for Dates, Halloween parties, or just casual hanging out with the boys. This Houndstooth shirt will give your personality a perfect first impression. Great gift to someone you love</b></p>. <br> <p><b>The Short sleeve casual button down shirt is made of polyester. Soft and comfortable to wear. Good Permeability and moisture absorption ability. Machine washable, low iron if necessary. You can wear it in many outdoor and indoor occasions.</b></p> <br> <p> This shirt fits perfectly with incredibly good polyester fabric according to your fashion.Whether you live in a mansion or a trailer this shirt will fit right in. </p> <p><b>Don\u2019t miss the chance and buy it now for an amazing experience!</b></p>",
        discounted_price: 44.95,
        product_link: "https://www.amazon.com/dp/B01FC1Q80W/"
    },
    
    {
        id: "B09JCQF3QQ",
        category: "Clean Cub",
        image: "https://m.media-amazon.com/images/I/91IJxWM-X-L._AC_SX679_.jpg",
        course_name: "Baby Led Weaning BLW Long Sleeve Apron Bib with Suction Cups for Infant and Toddlers (Pink, 12+)",
        description: "Proudly designed by a Minnesota mama. We use premium materials to keep our long-sleeved apron bib intact even after multiple washes.",
        discounted_price: 14.99,
        product_link: "https://www.amazon.com/dp/B09JCQF3QQ/"
    },
    
    {
        id: "B082VGZCS2",
        category: "Clean Cub",
        image: "https://m.media-amazon.com/images/I/91IJxWM-X-L._AC_SX679_.jpg",
        course_name: "Clean Cub Baby Led Weaning BLW Long Sleeve Apron Bib with Suction Cups for Infant and Toddlers (Pink, 6-12)",
        description: "Proudly designed by a Minnesota mama. We use premium materials to keep our long-sleeved apron bib intact even after multiple washes.",
        discounted_price: 14.99,
        product_link: "https://www.amazon.com/dp/B082VGZCS2/"
    },
    
    {
        id: "B07L52M2TB",
        category: "Clean Cub",
        image: "https://m.media-amazon.com/images/I/71xVVh2uIgL._AC_SX679_.jpg",
        course_name: "Clean Cub 12 Months+ Baby Led Weaning BLW Long Sleeve Apron Bib with Suction Cups for Infant and Toddlers - Waterproof & Washable | Must Have Baby-Led Weaning Supplies.",
        description: "Proudly designed by a Minnesota mama. We use premium materials to keep our long-sleeved apron bib intact even after multiple washes.",
        discounted_price: 14.99,
        product_link: "https://www.amazon.com/dp/B07L52M2TB/"
    },
    {
        id: "B07L5324PY",
        category: "Clean Cub",
        image: "https://m.media-amazon.com/images/I/71xVVh2uIgL._AC_SX679_.jpg",
        course_name: "Men\u2019s Houndstooth Button Up Short Sleeve Lounge Shirt | Black & White Casual Camp Shirt (3XL)",
        description: "Proudly designed by a Minnesota mama. We use premium materials to keep our long-sleeved apron bib intact even after multiple washes.",
        discounted_price: 14.99,
        product_link: "https://www.amazon.com/dp/B07L5324PY/"
    },
];

export default courses;