import React, { useState } from 'react';
import styled from "styled-components";
import { MdMenu, MdClose } from "react-icons/md";
import { Link } from 'react-router-dom';
import { useSidebarContext } from '../context/sidebar_context';
import { useCartContext } from '../context/cart_context';

const Navbar = () => {
  const { total_items } = useCartContext();
  const { openSidebar } = useSidebarContext();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Toggle the mobile menu
  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <NavbarWrapper>
      <div className='container'>
        <nav className='navbar'>
          {/* Brand */}
          <Link to='/' className='navbar-brand text-uppercase ls-1 fw-8'>
            <span>V</span>andelay Online
          </Link>

          {/* Navigation Links */}
          <div className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
            <Link to='/' className='nav-link' onClick={handleMobileMenuToggle}>
              Home
            </Link>
            <Link to='/categories' className='nav-link' onClick={handleMobileMenuToggle}>
              Categories
            </Link>
            <Link to='/about' className='nav-link' onClick={handleMobileMenuToggle}>
              About Us
            </Link>
            <Link to='/contact' className='nav-link' onClick={handleMobileMenuToggle}>
              Contact Us
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            type='button'
            className='mobile-menu-btn'
            onClick={handleMobileMenuToggle}
          >
            {isMobileMenuOpen ? <MdClose /> : <MdMenu />}
          </button>
        </nav>
      </div>
    </NavbarWrapper>
  );
};

const NavbarWrapper = styled.nav`
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.15) 0px 16px 12px -2px,
              rgba(0, 0, 0, 0.2) 0px 3px 7px -3px;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
  }

  .navbar-brand {
    font-size: 23px;
    text-decoration: none;
    color: #000;

    span {
      color: var(--clr-orange);
    }
  }

  .nav-links {
    display: flex;
    align-items: center;

    .nav-link {
      font-size: 18px;
      margin-right: 15px;
      text-decoration: none;
      color: #000;

      &:hover {
        color: var(--clr-orange);
      }
    }

    @media (max-width: 768px) {
      position: absolute;
      top: 80px;
      left: 0;
      right: 0;
      flex-direction: column;
      background-color: #fff;
      border-top: 1px solid #eee;
      display: none;

      &.active {
        display: flex;
      }

      .nav-link {
        margin: 15px 0;
        text-align: center;
      }
    }
  }

  .mobile-menu-btn {
    display: none;
    font-size: 28px;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

export default Navbar;
